.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100vw;
}

.logo {
  position: absolute;
  top: 36px;
  left: 0;
  width: 36px;
}

.usermenu {
  position: absolute;
  top: 10px;
  right: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
